import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const BodyArtPage = () => (
  <Layout descriptionText={"BodyArt"}>
    <Seo title="bodyart" />

    <Gallery categoryName="bodyart" />
  </Layout>
);

export default BodyArtPage;
